.video {
  z-index: 1;
  button {
    transition: 0.4s ease;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    height: 120px;
    width: 120px;
  }
  .thumbnail {
    width: 100%;
    height: auto;
    position: relative;

    img {
      position: relative;
      z-index: 1;
    }
    
    button {
      position: absolute;
      z-index: 2;
      top: calc(50% - calc(120px / 2));
      left: calc(50% - calc(120px / 2));
    }

    @media screen and (max-width: $break-mobile) {
      height: 85vh;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}