.video_modal, 
.vimeo_modal {
  background: $color-black;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 98;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s ease;

  button {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 1;
    @media screen and (max-width: $break-mobile) {
      height: 50px;
      width: 50px;
    }
  }

  video, iframe {
    transition: 0.4s cubic-bezier(0.83, 0, 0.17, 1);
    transform: translateY(10%);
    height: 100%;
    width: 100%;
    max-height: 80vh;
    max-width: 80vw;
    border-radius: 8px;
    position: relative !important;
    pointer-events: none !important;
    overflow: hidden;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    video, iframe {
      transform: translateY(0);
      pointer-events: all !important;
      z-index: 0;
    }
  }
}
.stories {
  &__projects {
    display: flex;
    flex-direction: row;
    gap: 2em;
    flex-wrap: nowrap;
    margin: 0 0 8em;
    justify-content: space-between;

    &__project {
      background: $color-light-blue;
      height: 750px;
      width: 100%;
      flex-shrink: 1;
      flex-grow: 1;
      position: relative;
      transition: 0.6s cubic-bezier(0.83, 0, 0.17, 1);
      padding: 2em;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      
      &:after {
        content: '';
        height: 8px;
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &:nth-of-type(1) {
        &:after {
          background: linear-gradient(90deg, get-color(intention) 0%, get-color(perspective) 100%);
        }
      }
      &:nth-of-type(2) {
        &:after {
          background: linear-gradient(90deg, get-color(perspective) 0%, get-color(grit) 100%);
        }
      }
      &:nth-of-type(3) {
        &:after {
          background: linear-gradient(90deg, get-color(grit) 0%, get-color(vulnerability) 100%);
        }
      }
      
      .thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        display: flex;
        align-items: center;
        transition: 0.6s cubic-bezier(0.83, 0, 0.17, 1);
        overflow: hidden;

        button {
          transition: 0.4s ease;
          opacity: 0;
          z-index: 1;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 0.8em;
          letter-spacing: 0.1em;
          height: 120px;
          width: 120px;
          pointer-events: none;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 1;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          transition: opacity 0.6s ease;
          transform: scale(1);
        }
      }

      .content {
        position: relative;
        z-index: 1;
        .information {
          transition: 0.6s ease;
          opacity: 0;
          margin: 2em 0;
          display: flex;
          flex-direction: column;
          gap: 1em;
          p {
            height: fit-content;
            overflow: hidden;
            margin: 0;
            span {
              display: block;
              perspective: 100px;
              transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
              transition-delay: var(--transition-delay);
              transform: translateY(100%);
            }

            &.title {
              font-size: 1.8em;
              font-weight: 500;
            }
            &.media {
              font-size: 1.2em;
              text-transform: uppercase;
            }
          }
        }
      }

      p.number {
        font-size: 2em;
        margin: 0;
        font-weight: 500;
        color: $color-white;
        position: relative;
        overflow: hidden;
        &::after {
          color: $color-black;
        }
        @media screen and (max-width: $break-mobile) {
          color: $color-black;
        }
      }

      @media screen and (min-width: $break-mobile) {
        &:hover {
          width: 60%;
          flex-shrink: 0;
          .thumbnail {
            width: calc(100% - 4em);
            height: 450px;
            top: 2em;
            left: 2em;
            img,button {
              opacity: 1;
            }
          }
          .information {
            opacity: 1;
            p span {
              transform: translateY(0);
            }
          }
          .thumbnail {
            cursor: default;
            button {
              pointer-events: all;
            }
          }
          p.number {
            &:before {
              transform: translateY(-100%);
            }
            &:after {
              transform: translateY(0);
            }
          }
        }
      }
    }
    @media screen and (max-width: $break-mobile) {
      flex-direction: column;
      &__project {
        width: 100%;
        height: auto;
        padding: 1em;
        cursor: default;
        .thumbnail {
          position: relative;
          height: 350px;
          top: unset;
          left: unset;
          img,button {
            opacity: 1;
          }
          button {
            pointer-events: all !important;
            height: 80px;
            width: 80px;
          }
        }
        .information {
          opacity: 1 !important;
          p span {
            transform: translateY(0) !important;
          }
        }
      }
    }
  }
}