.image__container {
  overflow: hidden;
}

.img-animate {
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
  &__scale {
    transform: scale(1.5);
    &.animated {
      transform: scale(1);
    }
  }
  &__reveal {
    &_left {
      clip-path: inset(0 100% 0 0);
      &.animated {
        clip-path: inset(0 0 0 0);
      }
    }
    &_right {
      clip-path: inset(0 0 0 100%);
      &.animated {
        clip-path: inset(0 0 0 0);
      }
    }
    &_top {
      clip-path: inset(0 0 100% 0);
      &.animated {
        clip-path: inset(0 0 0 0);
      }
    }
    &_bottom {
      clip-path: inset(100% 0 0 0);
      &.animated {
        clip-path: inset(0 0 0 0);
      }
    }
  }
}
@keyframes scale {
  0% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

span.letter {
  display: inline-block;
  opacity: 0;
}
span.text-animate,
span.letter-animate {
  width: fit-content;
  display: inline-block;
  text-align: left;
}
.text-animate,
.letter-animate {
  overflow: hidden;
}
.text-animate {
  word-break: break-word;
  hyphens: auto;
}

.animate {
  &__fadeIn {
    opacity: 0;
    transition: 1s ease-in-out;

    &.animated {
      opacity: 1;
    }
  }
  &__slide {
    &_up {
      transition: transform 0.8s ease-in-out;
      transform: translateY(25%);
      opacity: 0;

      &.animated {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  &__text {
    display: block;
    height: fit-content;
    overflow: hidden;
    position: relative;

    &__hide {
      color: transparent;
    }

    &:before,&:after {
      content: attr(data-content);
      font-family: $font-family;
      color: inherit;
      position: absolute;
      left: 0;
      top: 0;
      transition: 0.8s cubic-bezier(0.83, 0, 0.17, 1);
    }
    &:before {
      transform: translateY(0);
    }
    &:after {
      transform: translateY(100%);
    }
  }
}