$color-black: #000;
$color-white: #FFF;
$color-off-black: #191919;
$color-off-white: #D9D9D9;

$color-light-blue: #E8F5FF;
$color-muted: #C8CAE0;

$colors: (
  passion: #FBB9B5,
  vulnerability: #8388FC,
  stories: #7EDCF9,
  perspective: #91EDA0,
  connection: #E8A7F8,
  listen: #FFC450,
  grit: #5EBBFF,
  intention: #FFA677,
  courage: #B3B6FF,
  confidence: #FF9999,
  gratitude: #66D478,
  celebration: #FFDF8C
);
@each $name, $color in $colors {
  .color-#{$name} {
    --color: #{$color};
  }
}
@function get-color($value) {
  @return map-get($colors, $value);
}

//Gradients
$gradient-blue: conic-gradient(from 196deg at 62.19% 44.91%, get-color(grit) 0deg, get-color(vulnerability) 183.75000715255737deg, get-color(intention) 360deg);
$gradient-teal: conic-gradient(from 196deg at 62.19% 44.91%, get-color(stories) 0deg, get-color(grit) 183.75000715255737deg, get-color(celebration) 360deg);
$gradient-orange: conic-gradient(from 196deg at 62.19% 44.91%, get-color(listen) 0deg, get-color(confidence) 183.75000715255737deg, get-color(intention) 360deg);