.approach {
  &__slides {
    width: 100%;
    height: auto;
    margin: 4em 0 0;
    display: grid;
    grid-template-columns: auto 25vw 25vw;
    grid-template-rows: auto;
    gap: 2em;
    align-items: flex-end;

    @media screen and (max-width: $break-mobile) {
      grid-template-columns: 90% auto;
      grid-template-rows: auto;
      gap: 1em;
      width: 100vw;
      position: relative;
      left: -2em;
      padding: 2em;
      overflow: hidden;
    }

    &__text {
      grid-row: 1;
      grid-column: 2/4;

      @media screen and (max-width: $break-mobile) {
        grid-row: 2;
        grid-column: 1/2;
        display: flex;
        flex-direction: column-reverse;
      }

      .slide__text {
        font-size: 1.4em;
        line-height: 2em;
        display: none;
        &.active {
          display: block;
        }
      }

      &__navigator {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .slide-index {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          .number {
            margin: 0;
            &.current {
              font-weight: 500;
              font-size: 2.4em;
            }
            &.count {
              font-weight: 300;
              position: relative;
              top: -6px;
            }
          }
        }

        .buttons {
          display: flex;
          gap: 1em;

          .btn {
            background: none;
            height: 48px;
            width: 48px;

            &:disabled {
              opacity: 0.25;
              pointer-events: none;
            }
          }
        }
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      grid-row: 2;
      position: relative;

      @media screen and (max-width: $break-mobile) {
        grid-row: 1;
        &:not(.active) {
          height: 100%;
          width: auto;
          aspect-ratio: 1/1;
          transform: scale(0.8);
          transform-origin: bottom left;
        }
      }
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 8px;
        width: 100%;
      }

      &:nth-of-type(2) {
        &:after {
          background: linear-gradient(90deg, get-color(stories) 0%, get-color(grit) 100%);
        }
      }
      &:nth-of-type(3) {
        &:after {
          background: linear-gradient(90deg, get-color(intention) 0%, get-color(listen) 100%);
        }
      }
      &:nth-of-type(4) {
        &:after {
          background: linear-gradient(90deg, get-color(vulnerability) 0%, get-color(passion) 100%);
        }
      }

      &.active {
        grid-row: 1/3;
        grid-column: 1;
        @media screen and (max-width: $break-mobile) {
          grid-row: 1;
        }
      }
    }
  }
}