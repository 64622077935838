#partners {
  gap: 10vw;
  .image__right {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .accordion {
    width: 45vw;
    flex-shrink: 0;

    @media screen and (max-width: $break-mobile) {
      width: 100%;
    }

    a {
      text-decoration: none;
      text-transform: lowercase;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 0.2em;
    }
  }
}