.footer {
  background: $color-white;
  color: $color-black;
  width: 100%;
  padding: $gutter;
  z-index: -1;

  p.muted {
    color: #767676;
  }

  @media screen and (max-width: $break-mobile) {
    padding: $gutter / 2;
  }

  .sitemap {
    display: grid;
    grid-template-columns: 50% 1fr 1fr;
    padding: $gutter 0;
    text-transform: uppercase;

    @media screen and (max-width: $break-mobile) {
      padding: ($gutter / 2) 0;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
      gap: 2em;
    }

    &__links {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        margin: 1em 0;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    &__contact {
      .address {
        margin: 1em 0 3em;
        p {
          margin: 0.2em 0;
          &.line {
            text-transform: capitalize;
          }
        }
      }
      &__socials {
        list-style: none;
        padding: 0;
        display: flex;
        gap: 1em;
      }
    }
  }

  .copyright {
    border-top: 1px solid lighten($color-black, 40);
    padding-top: $gutter / 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__info {
      text-transform: uppercase;
    }
  }
}