@import "colors";
@import "fonts";
@import "structure";
@import "buttons";
@import "animations";
@import "navigation";
@import "footer";
@import "blobs";

//Sections
@import "hero";
@import "video";
@import "approach";
@import "stories";
@import "partners";
@import "team";
@import "quote";
@import "keystones";
@import "topics";
@import "contact";