.quote {
  display: flex;
  gap: 4em;
  @media screen and (max-width: $break-mobile) {
    flex-wrap: wrap;
    gap: 1em;
  }
  svg {
    flex-shrink: 0;
    @media screen and (max-width: $break-mobile) {
      width: 80px;
    }
  }
  &__contents {
    font-size: 2.4em;
    @media screen and (max-width: $break-mobile) {
      font-size: 1.8em;
    }
    p.quote {
      font-weight: 500;
    }
  }
}