.section#keystones {
  
  .sticky-container {
    display: grid;
    grid-template-columns: 40% auto;
    position: relative;
    width: 100vw;
    @media screen and (max-width: $break-mobile) {
      left: -$gutter / 2;
    }
  }

  .sticky-overlay {
    grid-column: 1/3;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    gap: 0;
    pointer-events: none;

    @media screen and (max-width: $break-mobile) {
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      left: 0;
    }

    .keystone-heading {
      width: 40%;
      text-align: right;
      pointer-events: all;
      h2 {
        width: 100%;
        font-size: clamp(2em, 25vw, 25em);
        text-align: right;
        margin: 0;
      }
      p {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.5em;
        margin: 0;
        position: relative;
        top: -2.5em;
        @media screen and (max-width: $break-mobile) {
          top: -0.8em;
        }
      }
    }

    .text-fade {
      width: 60%;
      height: 100vh;
      z-index: 1;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 6em;

      .desaturate {
        height: 100%;
        width: 100%;
        &:first-of-type {
          background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, $color-white 100%);
        }
        &:last-of-type {
          background: linear-gradient(360deg, $color-white 0%, rgba(0, 0, 0, 0) 100%);
        }
      }

      @media screen and (max-width: $break-mobile) {
        background: linear-gradient(360deg, $color-white 30%, rgba(0, 0, 0, 0) 50%, $color-white 70%);
      }
    }
  }
  ul {
    font-size: 6em;
    list-style: none;
    font-weight: 500;
    margin-top: -35vh;
    padding: 0 0 60vh 1em;
    grid-column: 2/2;
    li {
      color: $color-muted;
      margin: 10vh 0;
      transition: color 0.2s ease-in-out;
      &.is-inview {
        color: var(--color);
      }
    }
    @media screen and (max-width: $break-mobile) {
      font-size: clamp(1em, 6vw, 10em);
      li {
        margin: 10vh 0;
      }
    }
  }
}