.section#contact {
  color: $color-black;
  padding: inherit 10%;
  h2 {
    margin: 0;
  }
  p {
    font-size: clamp(1.4em, 2vw, 2em);
    max-width: 70%;
    font-weight: 300;
    a {
      font-weight: 500;
    }
    &:last-of-type {
      margin-top: 3em;
    }
  }
}