.section#topics {

  .section__content {
    margin-bottom: 6em;
  }

  .flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: $break-mobile) {
      flex-wrap: wrap;
      gap: 4em;
      .accordion__item.topic .title {
        color: $color-black !important;
      }
    }
  }
  .image__right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg,
    img {
      width: 80%;
      height: auto;
    }
    @media screen and (max-width: $break-mobile) {
      width: 100%;

      svg {
        animation: 25s spinLogo infinite linear;
        @keyframes spinLogo {
          from {
            transform: rotate(0deg);
          } to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}