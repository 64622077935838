.blob {
  position: absolute;
  filter: blur(80px);
  opacity: 0.75;
  z-index: -1;
  pointer-events: none;

  @media screen and (max-width: $break-mobile) {
    filter: blur(40px);
  }

  &#blob_001 {
    top: 2vh;
    right: 2vw;
    height: 18vh;
    width: 40vw;
    border-radius: 100%;
  }
  &#blob_002 {
    top: calc(50% - calc(35vh / 2));
    left: 0;
    height: 35vh;
    width: 35vw;
    border-radius: 75%;
  }
  &#blob_003 {
    bottom: 0;
    right: 0;
    height: 35vh;
    width: 35vw;
    border-radius: 100%;
    transform: rotate(-45deg);
  }
  &#blob_004 {
    height: 50vh;
    width: 35vw;
    border-radius: 100%;
    top: -15vw;
    right: -10vw;
    transform: rotate(135deg);
  }
  &#blob_005 {
    top: 10vh;
    left: -10vw;
    height: 35vh;
    width: 50vw;
    border-radius: 50%;
    transform: rotate(135deg);
  }
  &#blob_006 {
    bottom: 15vh;
    right: -10vw;
    height: 35vh;
    width: 35vw;
    border-radius: 100%;
    transform: rotate(135deg);
  }
  &#blob_007 {
    top: 0;
    left: -20vw;
    height: 35vh;
    width: 50vw;
    border-radius: 100%;
    transform: rotate(135deg);
    @media screen and (max-width: $break-mobile) {
      left: -40vw;
    }
  }

  &.blue {
    background: $gradient-blue;
  }
  &.teal {
    background: $gradient-teal;
  }
  &.orange {
    background: $gradient-orange;
  }
}