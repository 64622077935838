.hero {
  background: $color-white;
  width: 100vw;
  position: relative;
  padding: $gutter;
  padding-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  @media screen and (max-width: $break-mobile) {
    padding: $gutter / 2;
  }

  @media screen and (max-height: '680px') {
    height: fit-content;
  }

  .hero-logo {
    position: absolute;
    top: 55vh;
    left: 30vw;
    @media screen and (max-width: $break-mobile) {
      top: 20vh;
      left: 50vw;
    }
    svg,
    img {
      width: 50vw;
      min-width: 600px;
      height: auto;
      animation: 25s spinLogo infinite linear;
      @media screen and (max-width: $break-mobile) {
        min-width: unset;
        width: 80vw;
      }
      @keyframes spinLogo {
        from {
          transform: rotate(0deg);
        } to {
          transform: rotate(360deg);
        }
      }
    }
  }

  .content {
    width: 100%;
    position: relative;
    z-index: 3;
    min-height: calc(100vh - 25vh);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      margin: 0;  
      color: $color-black;
      text-transform: lowercase;
      span.h1-line {
        width: 100%;
        border-bottom: 1px solid $color-black;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        $h1-font-size: clamp(1em, calc(60vh / var(--headline-count)), 250px);
        font-size: $h1-font-size;
        height: $h1-font-size;
        margin: 0;
        text-align: right;

        @media screen and (max-width: $break-mobile) {
          font-size: 0.8em;
          height: 1.2em;
        }

        &:nth-of-type(2) {
          text-align: right;
          padding-right: 12vw;
        }
        &:nth-of-type(4) {
          text-align: right;
          padding-right: 8vw;
        }
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
    a {
      margin-top: 6em;
      @media screen and (max-width: $break-mobile) {
        margin-top: 2em;
      }
    }

    @media screen and (max-width: $break-mobile) {
      height: calc(90vh - #{$gutter});
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      h1 {
        font-size: clamp(1em, 14vw, 8em);
      }
    }
    @media screen and (max-height: '680px') {
      h1 {
        margin-top: 1em;
      }
    }
  }

  img,video {
    position: absolute;
    bottom: -35vh;
    right: -15vw;
    z-index: 1;
    aspect-ratio: 1/1;
    width: 150vh;
    height: auto;
    pointer-events: none;
    transform: left 0.2s ease, top 0.2s ease;

    &.background {
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      object-fit: cover;

      @media screen and (max-height: '680px') {
        height: 100%;
      }
    }
  }
}