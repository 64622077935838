.section#team {
  .team__heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10vw;
    width: 75%;
    margin: 4em 0;
    p {
      margin: 0;
    }
    p.heading {
      width: 50%;
      font-size: clamp(1.8em, 5vw, 6em);
      text-align: left;
    }
    p.description {
      text-align: right;
    }
    @media screen and (max-width: $break-mobile) {
      width: 100%;
      align-items: flex-start;
      flex-direction: column;
      gap: 1em;
      p.heading {
        font-size: clamp(1.8em, 9vw, 10em);
        width: 100%;
      }
      p.description {
        text-align: left;
        font-size: 1.4em;
      }
    }
  }
}