button.btn,
a.btn,
.btn {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  font-family: $font-family;
  cursor: pointer;
  transition: 0.4s ease;
  text-decoration: none;
  display: inline-block;
  
  &__secondary {
    padding: 1em 4em;
    border: 1px solid $color-black;
    font-weight: 500;
    border-radius: 11px;
    &:hover {
      background: $color-black;
      color: $color-white;
    }
  }

  &__dark {
    background: $color-black;
    color: $color-white;
    &:hover {
      background: lighten($color-black, 15);
    }
  }
  &__light {
    background: $color-white;
    color: $color-black;
    &:hover {
      background: $color-light-blue;
    }
  }
  &__grey {
    background: darken($color-white, 5);
    color: $color-black;
    &:hover {
      background: darken($color-white, 10);
    }
  }
  &__circle {
    height: 69px;
    width: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    &.small {
      height: 47px;
      width: 47px;
    }
  }
  &__scale {
    transition: transform 0.4s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: scale(1);
    &:hover {
      transform: scale(1.2);
    }
  }
  &__text-animate {
    &:hover {
      span.animate__text {
        &:before {
          transform: translateY(-100%);
        }
        &:after {
          transform: translateY(0);
        }
      }
    }
  }
  &__plain {
    background: none;
    color: inherit;
  }
  &__icon_text {
    display: flex;
    align-items: center;
    gap: 1em;
    font-size: 1.2em;
    text-decoration: none;
    span.bold {
      font-weight: 500;
    }

    @media screen and (max-width: $break-mobile) {
      .btn {
        flex-shrink: 0;
      }
      text-align: left;
    }
  }
}